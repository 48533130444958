<template>
  <footer class="footer">
    <div class="footer-top-section">
      <div class="wrapper">
        <div class="center">
          <ul class="nav">
            <template v-for="(item, i) in $parent.nav">
              <li class="nav__item" :key="i">
                <router-link :to="item.link" exact>
                  {{ item.title }}
                </router-link>
              </li>
            </template>
          </ul>
          <ul class="nav">
            <template v-for="item in $parent.textPageList">
              <li class="nav__item" :key="item.id">
                <a @click="$parent.goToPage(item.id)">
                  {{ item.title }}
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <div class="text" v-if="$parent.footerRequisites">
            <div class="desc">{{ $parent.footerRequisites }}</div>
          </div>
        </div>
        <div class="center">
          <a href="index.html" class="logo">
            <img src="./../assets/logo.svg" class="img" />
          </a>
        </div>
        <div class="right">
          <ul class="list">
            <li class="item" v-if="$parent.footerSupportEmail">
              <div class="title">Support:</div>
              <div class="desc">{{ $parent.footerSupportEmail }}</div>
            </li>
          </ul>
          <div class="social-list" v-if="$parent.socials">
            <a
              target="_blank"
              :href="item.link"
              class="link"
              v-for="(item, i) in $parent.socials"
              :key="i"
            >
              <img :src="item.img" class="img" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="left">
          <ul class="list">
            <li class="item">
              <img src="./../assets/mc_symbol.svg" class="img" />
            </li>
            <li class="item">
              <img src="./../assets/visa.svg" class="img" />
            </li>
            <li class="item">
              <img src="./../assets/3ds.png" class="img" />
            </li>
            <li class="item" v-if="$parent.currencyCode == 'PLN'">
              <img src="./../assets/blik.svg" class="img blik" />
            </li>
            <li class="item">
              <img
                src="./../assets/google-pay-primary-logo-logo-svgrepo-com.svg"
                class="img"
              />
            </li>
            <li class="item">
              <img src="./../assets/apple-pay.svg" class="img apple-pay" />
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="desc" v-if="$parent.footerCopyright">
            © {{ new Date().getFullYear() }} {{ $parent.footerCopyright }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: [],
  data: function () {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
