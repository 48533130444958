var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['app ' + _vm.$route.name],attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.pageLoaded)?_c('div',{staticClass:"page",attrs:{"id":"page"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.menuVisible)?_c('Menu',{attrs:{"currency-code":_vm.currencyCode},on:{"changeCurrency":_vm.changeCurrency}}):_vm._e()],1),_c('div',{staticClass:"mob menu-icon",class:{ active: _vm.menuVisible },on:{"click":_vm.toggleMenu}},[_c('span'),_c('span'),_c('span')]),_c('div',{staticClass:"header-container"},[(
            _vm.isAuth ||
            (!_vm.isAuth && _vm.$route.name != 'Profile' && _vm.$route.name != 'Cart')
          )?_c('Header',{attrs:{"cartContents":_vm.cartContents,"currency-code":_vm.currencyCode},on:{"openSaleSkinModal":_vm.openSaleSkinModal,"changeCurrency":_vm.changeCurrency,"logout":_vm.logout}}):_vm._e()],1),_c('router-view',{attrs:{"currency":_vm.currency,"currency-code":_vm.currencyCode,"addToCartChosenItem":_vm.addToCartChosenItem,"productCategory":_vm.productCategory},on:{"openOfferDetailsModal":_vm.openOfferDetailsModal,"formatDate":_vm.formatDate,"addToCart":_vm.addToCart,"goToProduct":_vm.goToProduct,"login":_vm.login,"forgotPass":_vm.forgotPass,"registration":_vm.registration}}),_c('div',{staticClass:"footer-container"},[(
            _vm.isAuth ||
            (!_vm.isAuth && _vm.$route.name != 'Profile' && _vm.$route.name != 'Cart')
          )?_c('Footer'):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.offerDetailsModalIsVisible)?_c('OfferDetailsModal',{attrs:{"orderToView":_vm.orderToView},on:{"closeOfferDetailsModal":_vm.closeOfferDetailsModal}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.saleSkinModalIsVisible)?_c('SaleSkinModal',{on:{"closeSaleSkinModal":_vm.closeSaleSkinModal,"createOffer":_vm.createOffer}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.acceptOfferModalIsVisible)?_c('AcceptOfferModal',{on:{"setError":_vm.setError}}):_vm._e()],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }